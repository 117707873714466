import request from '@/utils/request';

export function getEncryptParams(prevReqParams = {}) {
  const reqParams = {};
  if (prevReqParams.baseApiUrl) {
    reqParams.baseApiUrl = prevReqParams.baseApiUrl;
  }
  return request({
    url: '/',
    responseType: 'DHEncryptParam',
    encrypt: false,
    apiPath: '/api/goldhorse/keyExchange/encryptParam',
    ...reqParams,
  });
}

export function getEncryptKey(params = {}, prevReqParams = {}) {
  const req = request.create('DHEncryptKeyReq', params);
  const reqParams = {};
  if (prevReqParams.baseApiUrl) {
    reqParams.baseApiUrl = prevReqParams.baseApiUrl;
  }
  return request({
    url: '/keyExchange/encryptKey',
    requestBody: req,
    responseType: 'DHEncryptKeyRes',
    encrypt: false,
    apiPath: '/api/goldhorse/keyExchange/encryptKey',
    ...reqParams,
  });
}
