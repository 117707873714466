import { getEncryptKey, getEncryptParams } from '@/api/getway';
import {
  appOpenTokenModal,
  appSystemUpgrad,
  appUpdateVersion,
} from '@/utils/bridge';
import { isClient } from '@/utils/core';
import Request from '@easyview/request';

import { ResultCode } from './pb';

let webEntryType;
let deviceType;
let terminal;
let appVersion;

const isDevelopment = process.env.NODE_ENV === 'development';
export let baseApiUrl = '';
if (isDevelopment) {
  // baseApiUrl = 'http://172.16.50.100'; // dev
  // baseApiUrl = 'http://172.16.50.203'; // test
  baseApiUrl = 'https://hu-extramile.easyview.xyz';
  // baseApiUrl = "https://mobile.goldhorse.com.hk";
} else if (isClient()) {
  let host = location.hostname;
  if (location.protocol === 'https:') {
    host = location.host;
  }
  baseApiUrl = `${location.protocol}//${host}`;
}

function initRequest() {
  let protobuf;
  if (!isClient()) {
    protobuf = require('protobufjs/minimal');
    require('@/public/proto.server.min');
  } else {
    protobuf = window.protobuf;
  }
  const proto = protobuf.roots;
  const packagePrefix = 'default.hk.com.easyview.protobuf.bean';
  return new Request({
    baseApiUrl,
    apiPath: '/api/goldhorse/request',
    protobuf,
    proto,
    packagePrefix,
    fnGetEncryptParams: getEncryptParams,
    fnGetEncryptKey: getEncryptKey,
    headerParams: {
      deviceType,
      // domain,
      webEntryType,
      appVersion,
      terminal,
    },
  });
}

let req;
async function request(params) {
  if (!req) return;
  try {
    const { data } = await req.send(params);
    return data;
  } catch (error) {
    if (!isClient() || !error) throw error;
    const { statusCode, response } = error;
    const modalParams = {
      serverUrl: params.url,
      pageUrl: window?.location?.href,
      errorCode: statusCode?.toString(),
    };
    const { showTokenExpired = true, errorRedirect = true } = params;
    // 系统暂不可用
    if (statusCode === ResultCode.COMMON_SYSTEM_UNSTABLE) {
      const { data } = response.data;
      appSystemUpgrad({ systemUpgradTime: data.endTimestamp });
      return;
    }
    if (
      showTokenExpired &&
      statusCode === ResultCode.TOKEN_FAILURE &&
      hasLoginToken()
    ) {
      appOpenTokenModal(modalParams);
      // token expired
      throw response?.data;
    } else if (
      errorRedirect &&
      statusCode === ResultCode.COMMON_SIGNATURE_ERROR &&
      hasLoginToken()
    ) {
      appOpenTokenModal(modalParams);
      // signature errors
      throw response?.data;
    } else if (statusCode === ResultCode.APP_VERSION_IS_TOO_LOW) {
      // 强制跟新弹框
      appUpdateVersion();
      throw response?.data;
    } else {
      throw response?.data;
    }
  }
}

function hasLoginToken() {
  const len = window.location.pathname?.split('/');
  const routeName = len[len.length - 1] || '';
  if (routeName) {
    const pages = ['agreement', 'privacy'];
    return !pages.includes(routeName);
  }
}

request.create = function (messageName, obj) {
  return req.create(messageName, obj);
};
request.setToken = function (t) {
  req.setToken(t);
};
request.setAccountId = function (id) {
  req.setAccountID(id);
};
request.setLang = function (lang) {
  req.setLang(lang);
};
request.getAccoundId = function () {
  return req.accountID;
};
request.getToken = function () {
  return req.token;
};
request.setWebEntryType = function (type) {
  webEntryType = type;
};
request.setDeviceType = function (type = 1) {
  deviceType = type;
};
request.setTerminal = function (type = 0) {
  terminal = type;
};
request.setAppVersion = function (version) {
  appVersion = version;
};
request.init = function () {
  if (webEntryType === undefined) return;
  req = initRequest();
};
request.initEncrypt = async function () {
  await req.initEncrypt();
};

export default request;
